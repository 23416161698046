
import React from "react";
import Accordion from 'react-bootstrap/Accordion';
import ListGroup from 'react-bootstrap/ListGroup';

const title = "All Categories";


const ShopCategory = () => {
    return (
        <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>OLYMPIAD</Accordion.Header>
          <Accordion.Body>
            <ListGroup>
                <ListGroup.Item>Class 2nd</ListGroup.Item>
                <ListGroup.Item>Class 3rd</ListGroup.Item>
                <ListGroup.Item>Class 4th</ListGroup.Item>
                <ListGroup.Item>Class 5th</ListGroup.Item>
                <ListGroup.Item>Class 6th</ListGroup.Item>
                <ListGroup.Item>Class 7th</ListGroup.Item>
                <ListGroup.Item>Class 8th</ListGroup.Item>
            </ListGroup>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>PRIMARY EDUCATION</Accordion.Header>
          <Accordion.Body>
          <ListGroup>
                <ListGroup.Item>Class 2nd</ListGroup.Item>
                <ListGroup.Item>Class 3rd</ListGroup.Item>
                <ListGroup.Item>Class 4th</ListGroup.Item>
                <ListGroup.Item>Class 5th</ListGroup.Item>
            </ListGroup>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>SECONDARY EDUCATION</Accordion.Header>
          <Accordion.Body>
          <ListGroup>
                <ListGroup.Item>Class 6th</ListGroup.Item>
                <ListGroup.Item>Class 7th</ListGroup.Item>
                <ListGroup.Item>Class 8th</ListGroup.Item>
            </ListGroup>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>SENIOR SECONDARY EDUCATION</Accordion.Header>
          <Accordion.Body>
          <ListGroup>
                <ListGroup.Item>Class 9th</ListGroup.Item>
                <ListGroup.Item>Class 10th</ListGroup.Item>
                <ListGroup.Item>Class 11th</ListGroup.Item>
                <ListGroup.Item>Class 12th</ListGroup.Item>
            </ListGroup>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header>GRADUATION</Accordion.Header>
          <Accordion.Body>
          <ListGroup>
                <ListGroup.Item>Environmental B.Sc</ListGroup.Item>
                <ListGroup.Item>Applied Chemistery B.tech</ListGroup.Item>
                <ListGroup.Item>Basic Chemistry B.Sc</ListGroup.Item>
            </ListGroup>
            <p className="mt-4" >We can expand the courses according to your need, reach us at 📞+91-9027-522 365</p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="5">
          <Accordion.Header>POST GRADUATION</Accordion.Header>
          <Accordion.Body>
          <ListGroup>
                <ListGroup.Item>Physical Chemistry</ListGroup.Item>
                <ListGroup.Item>Analytical Chemistry</ListGroup.Item>
                <ListGroup.Item>Medical Chemistry</ListGroup.Item>
                <ListGroup.Item>Environmental Chemistry</ListGroup.Item>
                <ListGroup.Item>Inorganic Chemistry</ListGroup.Item>
                <ListGroup.Item>Organic Chemistry</ListGroup.Item>
                <ListGroup.Item>Physical Chemistry</ListGroup.Item>
            </ListGroup>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
}
 
export default ShopCategory;