import React from "react";


const Rating = () => {
    return (
        <span className="ratting">
            <i className="icofont-ui-rating"></i>
            <i className="icofont-ui-rating"></i>
            <i className="icofont-ui-rating"></i>
            <i className="icofont-ui-rating"></i>
            <i className="icofont-ui-rating"></i>
        </span>
    );
}
 
export default Rating;