
import React, { Component, Fragment, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";



const title = "Request a Demo";
const btnText = "Submit Now";

const requestDemo = () => {

    const [name, setName] = useState('');
    const [fName, setfName] = useState('');
    const [contactNumber, setContactNumber] = useState('');
    const [className, setClassName] = useState('');
    const [university, setUniversity] = useState('');

    //const scriptUrl = "https://script.google.com/macros/s/AKfycbz6y1EAmbTRKituqEQWy12KNLQQ5GNE9UN2ZbmZN-7mqu_1Y60OfWZYzEAagjvqrEjAww/exec"
    // const sendData = (e) => {
    //     e.preventDefault();
    //     const formEle = document.querySelector("form");
    //     const formDatab = new FormData(formEle);
    //     fetch(scriptUrl,
    //         {
    //             redirect: "follow",
    //             method: 'POST',
    //             body: formDatab,
    //             headers: {
    //                 "Content-Type": "text/plain;charset=utf-8",
    //             },
    //         })
    //     .then((res) => res.json())
    //     .then((data) => {
    //         console.log(data);
    //     })
    //     .catch((error) => {
    //         console.log(error);
    //     });
    // }

    return (
        <Fragment>
            <Header />
            <PageHeader title={'Demo Request Page'} curPage={'requestDemo'} />
            <div className="login-section padding-tb section-bg">
                <div className="container">
                    <div className="account-wrapper" style={{ maxWidth: "80%"}}>
                        <h3 className="title">{title}</h3>
                        {/* <form className="account-form">
                            <div className="form-group">
                                <input
                                    type="text"
                                    name="name"
                                    placeholder="Your Name *"
                                    // value={name}
                                    // required                                                                                
                                    // onChange={(e)=>setName(name)}
                                />
                            </div>
                            <div className="form-group">
                            <input
                                    type="text"
                                    name="fatherName"
                                    placeholder="Father's Name *"
                                    // value={fName}
                                    // required                                                                                
                                    // onChange={(e)=>setfName(fName)}
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="text"
                                    name="number"
                                    placeholder="Contact Number *"
                                    // value={contactNumber}
                                    // onChange={setContactNumber(contactNumber)}
                                    // required
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="text"
                                    name="class"
                                    placeholder="Your Class *"
                                    // value={className}
                                    // onChange={setClassName(className)}
                                    // required
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="text"
                                    name="subject"
                                    placeholder="School & Board *"
                                    // value={university}
                                    // onChange={setUniversity(university)}
                                    // required
                                />
                            </div>
                            <div className="form-group w-100 text-center">
                                <button onClick={sendData} className="lab-btn"><span>{btnText}</span></button>
                            </div>
                        </form> */}
                        <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSewxfRwySu2mE66XK9xhXDs9M1YqbTD1Opp6XuV-nZhk4lstw/viewform?embedded=true" width="100%" height="1250" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    );
}
 
export default requestDemo;
