import React from "react";
import { Link } from "react-router-dom";
import Rating from "../sidebar/rating";


const subTitle = "Loved by students";
const title = "Students Feedback";


const studentList = [
    {
        imgUrl: 'assets/images/feedback/student/girl-avtar.webp',
        imgAlt: 'student',
        name: 'Charu',
        degi: 'M.Sc.',
        desc: 'I would like to thank Furnace Classes for providing me mentors with finest teaching skills who have great command on the subjects which is reflected while they teach us, point to point explanation with examples help me to have better understanding of the topic. They always use best teaching tools. Best part of the mentors here is they motivate students to bring out their best.',
    },
    {
        imgUrl: 'assets/images/feedback/student/girl-avtar.webp',
        imgAlt: 'student',
        name: 'Manjusree Day Roy',
        degi: '6th Standard',
        desc: 'The teachers from Furnace Classes has been teaching my child for almost 2 years now, the classes are enjoyable and equally intresting. The teahcers have been blessing in guise for my child. The technique of their teaching is engrossing and draws easy attention of the child. The best part is I dont have to remind my child for the classes as her younger mind is already attached to the lucid teaching method. This platform is proved to be the best education partner for my child. ',
    }
]

const studentList2 = [
    {
        imgUrl: 'assets/images/feedback/student/student-avtars.webp',
        imgAlt: 'student',
        name: 'Aditya',
        degi: '10th Standard',
        desc: 'My father came accross with an advertisement of Furnace Classes on an online platform. He asked me to join the class and i joined. I was pretty nervous in my class but I gain confidence in my 3rd session. Furnace Classes made me communicate freely and I was able to speak in a more fluent way. The teachers are really encouraging and they motivate to be like them. Thank you Furnace Classes and its teachers for introducing a change in me.',
    },
    {
        imgUrl: 'assets/images/feedback/student/student-avtars.webp',
        imgAlt: 'student',
        name: 'Paavan',
        degi: '3rd Standard',
        desc: 'My son was struggling with reading English. During covid lockdown we cam accross the furnace classes and their specialized reading, creative writing course. My son loved the classes and we are having an association of more than three years now. The teaching skills are wonderfull and inovative which captivates these youngs over active minds. We are now taking other courses offered by Furnace Classes.',
    },
]


const Student = () => {
    return (
        <div className="student-feedbak-section padding-tb shape-img">
            <div className="container">
                <div className="section-header text-center">
                    <span className="subtitle">{subTitle}</span>
                    <h2 className="title">{title}</h2>
                </div>
                <div className="section-wrapper">
                    <div className="row justify-content-center row-cols-lg-2 row-cols-1">
                        {/* <div className="col">
                            <div className="sf-left">
                                <div className="sfl-thumb">
                                    <img src="assets/images/feedback/01.jpg" alt="student feedback" />
                                    <a href="https://www.youtube.com/embed/MU3qrgR2Kkc" className="video-button popup" target="_blank"><i className="icofont-ui-play"></i></a>
                                </div>
                            </div>
                        </div> */}
                        <div className="col" style={{zIndex: 1}}>
                            {studentList.map((val, i) => (
                                <div className="stu-feed-item" key={i}>
                                    <div className="stu-feed-inner">
                                        <div className="stu-feed-top">
                                            <div className="sft-left">
                                                <div className="sftl-thumb">
                                                    <img width="60px" src={`${val.imgUrl}`} alt={val.imgAlt} />
                                                </div>
                                                <div className="sftl-content">
                                                    <Link to="/team-single"><h6>{val.name}</h6></Link>
                                                    <span>{val.degi}</span>
                                                </div>
                                            </div>
                                            <div className="sft-right">
                                                <Rating />
                                            </div>
                                        </div>
                                        <div className="stu-feed-bottom">
                                            <p>{val.desc}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="col">
                            {studentList2.map((val, i) => (
                                <div className="stu-feed-item" key={i}>
                                    <div className="stu-feed-inner">
                                        <div className="stu-feed-top">
                                            <div className="sft-left">
                                                <div className="sftl-thumb">
                                                    <img width="60px" src={`${val.imgUrl}`} alt={val.imgAlt} />
                                                </div>
                                                <div className="sftl-content">
                                                    <Link to="/team-single"><h6>{val.name}</h6></Link>
                                                    <span>{val.degi}</span>
                                                </div>
                                            </div>
                                            <div className="sft-right">
                                                <Rating />
                                            </div>
                                        </div>
                                        <div className="stu-feed-bottom">
                                            <p>{val.desc}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default Student;