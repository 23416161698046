import React, { Fragment } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
// import AchievementTwo from "../component/section/achievement-2";
// import Blog from "../component/section/blog";
// import Instructor from "../component/section/instructor";
import Skill from "../component/section/skill";
import Student from "../component/section/student";




const subTitle = "OUR VISION";
const title = "TO STRENGTHEN EDUCATION ECOSYSTEM";
const desc = "The vision statements written by educational institutions are pragmatic and they are designed usually to attract mentors and learners. But at Furnace Classes we do not want to decorate and plate goals we cannot achieve, instead we are passionately dedicated to help all the students to perform best in whatever subject they wish to. We also provide guidance of transforming their personality and confidence level to be future ready.";

const year = "5+";
const expareance = "Years Of Experiences";

const aboutList = [
    {
        desc: "Education for all students with no barrier of distance."
    }, 
    {
        desc: "Experts with dedication and passion to train students."
    }
]


const AboutPage = () => {
    return ( 
        <Fragment>
            <Header />
            <PageHeader title={'About Our Furnace Classes'} curPage={'About'} />
            <div className="about-section style-3 padding-tb section-bg" id="aboutSection">
                <div className="container">
                    <div className="row justify-content-center row-cols-xl-2 row-cols-1 align-items-center">
                        <div className="col">
                            <div className="about-left">
                                <div className="about-thumb">
                                    <img src="assets/images/about/01.jpg" alt="about" />
                                </div>
                                <div className="abs-thumb">
                                    <img src="assets/images/about/02.jpg" alt="about" />
                                </div>
                                <div className="about-left-content">
                                    <h3>{year}</h3>
                                    <p>{expareance}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="about-right">
                                <div className="section-header">
                                    <h4 className="subtitle">{subTitle}</h4>
                                    <p>{desc}</p>
                                </div>
                            </div>
                            <div className="about-right">
                                <div className="section-header">
                                <span className="subtitle">OUR MISSION</span>
                                    <p>Witnessing children with ample amount of talent and less facilities struggling for performing better is heart quenching. Our mission is to provide education at each and every doorstep despite of various troubles students facing.</p>
                                </div>
                                <div className="section-wrapper">
                                    <h6>Core of the mission involves:-</h6>
                                    <ol className="lab-ul">
                                        {aboutList.map((val, i) => (
                                            <li key={i}>
                                                <div className="sr-right">
                                                    <p>{val.desc}</p>
                                                </div>
                                            </li>
                                        ))}
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Student />
            {/* <Instructor /> */}
            <Skill />
            {/* <AchievementTwo /> */}
            {/* <Blog /> */}
            <Footer />
        </Fragment>
    );
}

export default AboutPage;
 