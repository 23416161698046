
import { Link } from "react-router-dom";
import React from "react";


const title = "STRENGTHS WE ARE PROVIDING:-";
const btnText = "Sign Up Now";

const skillList = [
    {
        imgUrl: 'assets/images/skill/icon/01.jpg',
        imgAlt: 'skill',
        title: 'Subject experts of different fields.',
        desc: 'You pick the schedule.',
    },
    {
        imgUrl: 'assets/images/skill/icon/02.jpg',
        imgAlt: 'skill',
        title: 'Affordable quality education.',
        desc: 'You pick the schedule.',
    },
    {
        imgUrl: 'assets/images/skill/icon/03.jpg',
        imgAlt: 'skill',
        title: 'Equipping learners with knowledge and skills.',
        desc: 'You pick the schedule.',
    },
    {
        imgUrl: 'assets/images/skill/icon/04.jpg',
        imgAlt: 'skill',
        title: 'Confidence building for students to realize their full potential.',
        desc: 'You pick the schedule.',
    },
]


const Skill = () => {
    return (
        <div className="skill-section padding-tb">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-4 col-12">
                        <div className="section-header">
                            <h2 className="title">{title}</h2>
                        </div>
                    </div>
                    <div className="col-lg-8 col-12">
                        <div className="section-wrpper">
                            <div className="row g-4 justify-content-center row-cols-sm-2 row-cols-1">
                                {skillList.map((val, i) => (
                                    <div className="col" key={i}>
                                        <div className="skill-item">
                                            <div className="skill-inner">
                                                <div className="skill-thumb">
                                                    <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                                </div>
                                                <div className="skill-content">
                                                    <p>{val.title}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default Skill;