import React, { Fragment, useState } from "react";


import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
import ShopCategory from "../component/sidebar/shop-category";


const title = "The Title here";
const subtitle = "Product Description";
const price = "$ 340.00";
const desc = "Energistia an deliver atactica metrcs after avsionary Apropria trnsition enterpris an sources applications emerging 	psd template.";
const reviwtitle = "Add a Review";


const popularCategories = () => {
    const [reviewShow, setReviewShow] = useState(true);

    return (
        <Fragment>
            <Header />
            <PageHeader title={'POPULAR CATEGORIES'} curPage={'Categories'} />
            <div className="shop-single padding-tb aside-bg">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-12 col-12">
                            <article>
                                <ShopCategory />
                            </article>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    );
}
 
export default popularCategories;
